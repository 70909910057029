.toast-title {
  font-weight: bold;
}
.toast-message {
  word-wrap: break-word;
}
.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  opacity: 0.5;
}
.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.8;
}
/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}
.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}
.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}
.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-left {
  top: 12px;
  left: 12px;
}
.toast-top-right {
  top: 12px;
  right: 12px;
}
.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}
.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}
#toast-container {
  position: fixed;
  z-index: 999999;
  /*overrides*/
}
#toast-container > div {
  position: relative;
  overflow: hidden;

  width: 300px;
  @include box-shadow(0 6px 12px rgba(0,0,0,.175));
  color: #FFFFFF;
  opacity: 0.8;
}
#toast-container > :hover {
  @include box-shadow(0 6px 12px rgba(0,0,0,.375));
  opacity: 1;
  cursor: pointer;
}
.toast-info-icon:before { content: $fa-var-info-circle; }
.toast-success-icon:before { content: $fa-var-check; }
.toast-warning-icon:before { content: $fa-var-warning; }
.toast-error-icon:before { content: $fa-var-times-circle; }


#toast-container.toast-top-center > div,
#toast-container.toast-bottom-center > div {
  width: 300px;
  margin: auto;
}
#toast-container.toast-top-full-width > div,
#toast-container.toast-bottom-full-width > div {
  width: 96%;
  margin: auto;
}
.toast {
  background-color: #030303;
}
.toast-success {
  background-color: $alert-success-bg;
  color: $alert-success-text !important;
}
.toast-error {
  background-color: $alert-danger-bg;
  color: $alert-danger-text !important;
}
.toast-info {
  background-color: $alert-info-bg;
  color: $alert-info-text !important;
}
.toast-warning {
  background-color: $alert-warning-bg;
  color: $alert-warning-text !important;
}
.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}
/*Animations*/
.toast {
  opacity: 1 !important;
}
.toast.ng-enter {
  opacity: 0 !important;
  transition: opacity .3s linear;
}
.toast.ng-enter.ng-enter-active {
  opacity: 1 !important;
}
.toast.ng-leave {
  opacity: 1;
  transition: opacity .3s linear;
}
.toast.ng-leave.ng-leave-active {
  opacity: 0 !important;
}
/*Responsive Design*/
@media all and (max-width: 240px) {
  #toast-container > div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  #toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  #toast-container > div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  #toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  #toast-container > div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
