.cmd-link {
  color: #3e67a3;
  text-decoration: none;
  display: inline-block;
  height: 1.4em;
  vertical-align: middle;
  background: no-repeat 0 2px;
  white-space: nowrap;
}

.cmd-link:visited {
  color: #3e67a3;
}

.cmd-link:hover {
  text-decoration: underline;
}

.cmd-link-add {
  background-image: url('../img/ico-add.png');
  padding-left: 20px;
}

.cmd-link-edit {
  background-image: url('../img/ico-edit.png');
  padding-left: 20px;
}

.cmd-link-delete {
  background-image: url('../img/ico-delete.png');
  padding-left: 20px;
}

.cmd-link-cancel {
  background-image: url('../img/ico-cancel.png');
  padding-left: 20px;
}

.cmd-link-define {
  background: url('../img/ico-define.png') no-repeat 0 2px;
  padding-left: 20px;
}

.cmd-link-refs {
  background-image: url('../img/ico-refs.png');
  padding-left: 20px;
}

.cmd-link-acl {
  background-image: url('../img/ico-acl.png');
  padding-left: 20px;
}

.cmd-link-noicon {
  padding-left: 20px;
}

.cmd-link-mdv-publish {
  background-image: url('../img/ico-mdv-publish.png');
  padding-left: 20px;
}

.cmd-link-mdv-revoke {
  background-image: url('../img/ico-mdv-revoke.png');
  padding-left: 20px;
}

.cmd-link-report {
  background-image: url('../img/ico-report.png');
  padding-left: 20px;
}

.cmd-link-create-wide-tables {
  background-image: url('../img/ico-create-wide-tables.png');
  padding-left: 20px;
}

.cmd-link-drop-wide-tables {
  background-image: url('../img/ico-delete.png');
  padding-left: 20px;
}

.cmd-link-populate-wide-tables {
  background-image: url('../img/ico-populate-wide-tables.png');
  padding-left: 20px;
}

.cmd-link-attachment {
  background-image: url('../img/ico-attachment.png');
  padding-left: 20px;
}

.cmd-link-button {
  border: solid 1px silver;
  padding: 0 4px;
  padding-left: 24px;
  background-position: 4px 2px;
  height: 1.4em;
  line-height: 1.4em;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background: rgb(254, 254, 253); /* Old browsers */
  background: -moz-linear-gradient(top, rgba(254, 254, 253, 1) 0%, rgba(226, 226, 226, 1) 42%, rgba(214, 214, 214, 1) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(254, 254, 253, 1)), color-stop(42%, rgba(226, 226, 226, 1)), color-stop(100%, rgba(214, 214, 214, 1))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(254, 254, 253, 1) 0%, rgba(226, 226, 226, 1) 42%, rgba(214, 214, 214, 1) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgba(254, 254, 253, 1) 0%, rgba(226, 226, 226, 1) 42%, rgba(214, 214, 214, 1) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgba(254, 254, 253, 1) 0%, rgba(226, 226, 226, 1) 42%, rgba(214, 214, 214, 1) 100%); /* IE10+ */
  background: linear-gradient(to bottom, rgba(254, 254, 253, 1) 0%, rgba(226, 226, 226, 1) 42%, rgba(214, 214, 214, 1) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fefefd', endColorstr='#d6d6d6', GradientType=0); /* IE6-9 */
}
